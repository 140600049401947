import React from "react";
import "./faq.css";
import Accordion from "../accordion";

const Faq = () => {
  return (
    <div>
      {/* <button className="accordion">How much will it cost?</button>
      <div className="panel">
        <p>
          If you have a valid referral from a GP, psychiatrist or paediatrician
          you will not be charged a fee as Medicare will be billed directly on
          your behalf.
        </p>
      </div>

      <button className="accordion">Why is there a wait list?</button>
      <div class="panel">
        <p>
          There is a high demand for bulk billing psychology services therefore
          there is a likelihood you will be placed on a waiting list. Due to Bay
          Islands Psychology being a 100% bulk billing service this means that
          the demand can be quite high. However, wait list times will vary.
        </p>
      </div>

      <button className="accordion">How many sessions will I get?</button>
      <div className="panel">
        <p>
          If you have a valid referral from your GP, psychiatrist or
          paediatrician you will be eligible for up to 10 sessions per calendar
          year. These are generally provided in two blocks (6 sessions and then
          4 sessions). All these sessions are 100% bulk billed.
        </p>
      </div>

      <button className="accordion">Can I contact you in an emergency?</button>
      <div className="panel">
        <p>
          Although, we will do our best to support you during a crisis, Bay
          Islands Psychology is not an emergency service and therefore is unable
          to guarantee a timely response to urgent messages left via phone,
          email or online during and outside of business hours. If you require
          urgent assistance and have not been able to get in contact with the
          clinic, please contact emergency services (e.g. call 000, or
          lifeline’s 24-hour telephone counselling service on 13 11 14) to
          provide crisis support.
        </p>
      </div>

      <button className="accordion">Is what I say confidential?</button>
      <div className="panel">
        <p>
          Absolutely. Your information is kept secure and confidential and will
          not be disclosed to any other party, except under circumstances that
          are explained in Bay Islands Confidentiality Policy, which you will be
          told about in your first session.
        </p>
      </div>

      <button className="accordion">
        Referral Information for GP’s and Funding information
      </button>
      <div className="panel">
        <p>
          Funding under Medicare
          <ul>
            <li>Better Access Program</li>
            <li>ASD, Autism Program</li>
            <li>Eating Disorders Program </li>
            <li>Chronic Health Condition</li>
            <li>Acceptance and Commitment Therapy</li>
          </ul>
          NDIS - Must be plan managed or self-managed
          Department of Veteran Affairs (DVA)
          Victims of Crime.
          NOTE: Preferred communications is for referrals to be sent through Medical Objects if possible.
        </p>
      </div> */}
      <Accordion
        title="How much will it cost?"
        content="If you have a valid referral from a GP, psychiatrist or paediatrician
        you will not be charged a fee as Medicare will be billed directly on
        your behalf."
      />
      <Accordion
        title="Why is there a wait list?"
        content="There is a high demand for bulk billing psychology services therefore
        there is a likelihood you will be placed on a waiting list. Due to Bay
        Islands Psychology being a 100% bulk billing service this means that
        the demand can be quite high. However, wait list times will vary."
      />
      <Accordion
        title="How many sessions will I get?"
        content="If you have a valid referral from your GP, psychiatrist or
        paediatrician you will be eligible for up to 10 sessions per calendar
        year. These are generally provided in two blocks (6 sessions and then
        4 sessions). All these sessions are 100% bulk billed."
      />
      <Accordion
        title="Can I contact you in an emergency?"
        content="Although, we will do our best to support you during a crisis, Bay
        Islands Psychology is not an emergency service and therefore is unable
        to guarantee a timely response to urgent messages left via phone,
        email or online during and outside of business hours. If you require
        urgent assistance and have not been able to get in contact with the
        clinic, please contact emergency services (e.g. call 000, or
        lifeline’s 24-hour telephone counselling service on 13 11 14) to
        provide crisis support."
      />
      <Accordion
        title="Is what I say confidential?"
        content="Absolutely. Your information is kept secure and confidential and will
        not be disclosed to any other party, except under circumstances that
        are explained in Bay Islands Confidentiality Policy, which you will be
        told about in your first session."
      />
      <Accordion
        title="Referral Information for GP’s and Funding information"
        content="Funding under Medicare
        <ul>
          <li>Better Access Program</li>
          <li>ASD, Autism Program</li>
          <li>Eating Disorders Program </li>
          <li>Chronic Health Condition</li>
          <li>Acceptance and Commitment Therapy</li>
        </ul>
        NDIS - Must be plan managed or self-managed
        Department of Veteran Affairs (DVA)
        Victims of Crime.
        NOTE: Preferred communications is for referrals to be sent through Medical Objects if possible."
      />
    </div>
  );
};

export default Faq;
