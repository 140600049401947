import React from 'react';
import "./button.css"

const Button = () => {
    return (
        <div>
            <button className="bi-button">
                <div className="text">
                    MAKE ENQUIRY
                </div>
            </button>
        </div>
    )
}

export default Button;