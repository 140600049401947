import React from 'react';
import "./service-card.css"

const ServiceCard = (props) => {
    if (props.direction === 'left' || !props.isDesktop) {
        return (
            <div className="service-card">
                <div className="service-card-image">
                    <img src={props.pic} className="service-card-image-pic" alt="bay_islands_psychology_logo" />
                    <div className="service-card-image-overlay"></div>
                </div>
                <div className="service-card-info">
                    <h4>{props.header}</h4>
                    {props.info}
                </div>
            </div>
        )
    } else {
        return (
            <div className="service-card">
                <div className="service-card-info">
                    <h4>{props.header}</h4>
                    {props.info}
                </div>
                <div className="service-card-image">
                    <img src={props.pic} className="service-card-image-pic" alt="bay_islands_psychology_logo" />
                    <div className="service-card-image-overlay"></div>
                </div>
            </div>
        )
    }
}

export default ServiceCard;