import React from 'react';
import './navbar.css';
import Button from '../button'

import { FaBars, FaTimes } from "react-icons/fa";

class NavBar extends React.Component {

    state = {
        mobileNavActive: false
    }
    // componentDidMount = async () => {
    //     document.addEventListener("click", this.handleOutsideClick, false);
    // };
    
    //   componentWillUnmount() {
    //     document.removeEventListener("click", this.handleOutsideClick, false);
    // }

    // handleOutsideClick = e => {
    //     //ignore clicks on the component itself
    //     if (this.sidebarNode.contains(e.target)) {
    //       return;
    //     }
    
    //     if (this.state.mobileNavActive) {
    //       this.toggleNav();
    //     }
    //   };

    toggleNav = () => {
        this.setState({ mobileNavActive: !this.state.mobileNavActive });
    };

    render() {
        return (
            <div>
                <div className="bi-navbar">
                    <div className="bi-navbar-item">
                        <a href="#home">HOME</a>
                    </div>
                    <div className="bi-navbar-item">
                        <a href="#about">ABOUT</a>
                    </div>
                    <div className="bi-navbar-item">
                        <a href="#services">SERVICES</a>
                    </div>
                    <div className="bi-navbar-item">
                        <a href="#faq">FAQ</a>
                    </div>
                    <div className="bi-navbar-item">
                        <a href="#contact">CONTACT</a>
                    </div>
                    <div className="bi-navbar-right">
                        <a href = "mailto: julie@bayislandspsychology.com.au">
                            <Button />
                        </a>
                    </div>
                </div>
                <div className="bi-navbar-mobile">
                    <div
                    ref={sidebarNode => {
                        this.sidebarNode = sidebarNode;
                    }}
                    >
                        <div className="bi-navbar-right-mobile">
                            <div className="bi-bars-icon" onClick={this.toggleNav}>
                                {this.state.mobileNavActive ? <FaTimes/> : <FaBars/> }
                            </div>
                            <div className={this.state.mobileNavActive ? "bi-navbar-mobile-show" : "bi-navbar-mobile-hidden"}>
                                <div className="bi-navbar-container-mobile">
                                    <a href="#home">
                                        <div className="bi-navbar-item-mobile" onClick={this.toggleNav}>
                                            HOME
                                        </div>
                                    </a>
                                    <a href="#about">
                                        <div className="bi-navbar-item-mobile" onClick={this.toggleNav}>
                                            ABOUT
                                        </div>
                                    </a>
                                    <a href="#services">
                                        <div className="bi-navbar-item-mobile" onClick={this.toggleNav}>
                                            SERVICES
                                        </div>
                                    </a>
                                    <a href="#contact">
                                        <div className="bi-navbar-item-mobile" onClick={this.toggleNav}>
                                            CONTACT
                                        </div>
                                    </a>
                                    <a href = "mailto: julie@bayislandspsychology.com.au">
                                        <div className="bi-navbar-item-mobile" onClick={this.toggleNav}>
                                            MAKE ENQUIRY
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NavBar;