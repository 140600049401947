import React from 'react';

const Footer = () => {
    return (
        <div style={styles.footer}>
            © Bay Islands Psychology 2023. All Rights Reserved.
        </div>
    )
}

export default Footer;

const styles = {
    footer: {
        width: "100%",
        height: "55px",
        backgroundColor: "#6D0812",
        color: "#fff",
        position: "relative",
        bottom: "0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
    }
}