import React from "react";
import "./home.css";
import NavBar from "../../shared/navbar";
import Footer from "../../shared/footer";
import Logo from "../../../assets/images/bay_islands_psychology_logo.png";
import DrJulieNos from "../../../assets/images/dr_julie_nos_400_400.jpg";
import ServiceCard from "../../shared/service-card";
import AlcoholPic from "../../../assets/images/alcohol_drug_issues.jpg";
import ParentingPic from "../../../assets/images/parenting_issues.jpg";
import StressPic from "../../../assets/images/stress_issues.jpg";
import AngerPic from "../../../assets/images/anger_issues.jpg";
import FamilyPic from "../../../assets/images/family_issues.jpg";
import { FaMapMarkerAlt, FaEnvelope, FaPhone } from "react-icons/fa";
import Maps from "../../shared/maps";
import WelcomeVideo from "../../../assets/videos/dr_julie_nos_welcome_video.mov";
import WelcomePosterImage from "../../../assets/images/welcome_poster_image.jpg";
import Faq from "../../shared/faq";

class Home extends React.Component {
  state = {
    isDesktop: true,
  };

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate = () => {
    this.setState({ isDesktop: window.innerWidth > 720 });
  };

  render() {
    const { isDesktop } = this.state;
    return (
      <div>
        <div className="bi-home-header">
          <div className="bi-home-header-hero-image">
            <NavBar />
            <div className="bi-home-header-hero-text">
              <p><u>Learn</u> from yesterday </p>
              <p><u>Live</u> for today</p>
              <p><u>Hope</u> for tomorrow </p>
              <br/>
              "Einstein"
            </div>
            <div id="about" className="bi-home-header-welcome">
              <div className="row">
                <div className="col-5">
                  <img
                    src={Logo}
                    className="bi-home-header-welcome-logo"
                    alt="bay_islands_psychology_logo"
                  />
                </div>
                <div className="col-7">
                  <h1>Welcome</h1>
                  <p>
                    Bay Islands Psychology is a wellbeing clinic that provides a
                    respectful, friendly and professional environment to
                    administer assessment and provide psychotherapy for the
                    people of South Moreton Bay Islands.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bi-home-header-fix"></div>
        <div className="bi-home-body">
          <div className="bi-home-body-appointment-section">
            <a href="mailto: julie@bayislandspsychology.com.au">
              <button className="bi-home-body-appointment-section-button">
                MAKE AN ENQUIRY
              </button>
            </a>
          </div>
          <div className="bi-home-body-doctor-section">
            <div className="bi-home-container">
              <div className="bi-home-body-dr-julie-nos-wrapper">
                <div className="bi-home-body-dr-julie-nos-text">
                  <div className="bi-home-body-dr-julie-nos-image-wrapper">
                    <img
                      src={DrJulieNos}
                      className="bi-home-body-dr-julie-nos-image"
                      alt="Dr Julie Nos"
                    />
                  </div>
                  <h2>Dr Julie Nos</h2>
                  <h3>Clinical Psychologist, PhD.</h3>
                  At times in our lives we can feel depressed, anxious, stressed
                  or overwhelmed. Professional psychological help can assist in
                  clarifying concerns, explore options and develop more
                  effective coping strategies to improve the quality of life and
                  overall mental health.
                  <br />
                  <br />
                  Julie is a Clinical Psychologist who has obtained the highest
                  level of post-graduate qualifications in Clinical Psychology
                  and has expertise in the treatment of a wide range of mental,
                  emotional and relationship issues.
                  <br />
                  <br />
                  Julie believes it is a privilege and honour to support people
                  in their journey to find the wisdom that is within and the
                  courage to engage in a life worth living.
                  <br />
                  <br />
                  Julie uses a range of evidence based psychological treatments
                  and abides by professional standards and ethical guidelines,
                  which include issues such as confidentiality and duty of care.
                  <br />
                  <br />
                  She has a background of solid training as well as diverse work
                  and life experiences. She has advanced training in a variety
                  of areas including:
                  <ul>
                    <li>Cognitive Behaviour Therapy</li>
                    <li>Dialectical Behaviour Therapy </li>
                    <li>
                      Parenting Programs (accredited Parents under Pressure
                      Program Therapist)
                    </li>
                    <li>Schema Therapy </li>
                    <li>Acceptance and Commitment Therapy</li>
                  </ul>
                  <br />
                  <video
                    className="bi-home-body-dr-julie-nos-welcome-video"
                    controls="controls"
                    preload="none"
                    poster={WelcomePosterImage}
                  >
                    <source src={WelcomeVideo} type="video/mp4" />
                  </video>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div id="services" className="bi-home-body-services-section">
            <div className="bi-home-container">
              <h2>Services</h2>
              <p>Individual counselling is provided for:</p>
            </div>
            <div className="bi-home-body-services-section-cards">
              <ServiceCard
                isDesktop={isDesktop}
                direction="left"
                pic={StressPic}
                header="Depressive Disorders"
                info="Depression is a serious but treatable disorder that affects a diverse range of people from young to elderly."
              />
              <ServiceCard
                isDesktop={isDesktop}
                direction="right"
                pic={AlcoholPic}
                header="Anxiety Disorders"
                info="Although feelings of anxiety are common for most people it can be a debilitating disorder which significantly impacts a person’s worldview."
              />
              <ServiceCard
                isDesktop={isDesktop}
                direction="left"
                pic={ParentingPic}
                header="PTSD and Trauma"
                info="Being exposed to one or more traumatic events may result in a range of symptoms being experienced which may include feelings of numbness, shock, anger, fear, aggression or sadness."
              />
              <ServiceCard
                isDesktop={isDesktop}
                direction="right"
                pic={AngerPic}
                header="Addictive Disorders"
                info="Alcohol and drug use problems can cause significant distress in people’s lives."
              />
              <ServiceCard
                isDesktop={isDesktop}
                direction="left"
                pic={FamilyPic}
                header="Parenting problems"
                info="Becoming a new parent or managing the challenges of older children can feel overwhelming at times."
              />
              <div className="bi-home-body-services-section-more-services">
                <div className="bi-home-container">
                  <p>
                    Other presenting issues may include Eating Disorders,
                    Relationship problems, ASD &#38; Autism, Work stress,
                    Chronic Health problems, Grief and Loss, and Personality
                    Disorders.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="faq" className="bi-home-body-services-section">
            <div className="bi-home-container">
              <h2>Frequently Asked Questions</h2>
              <Faq />
            </div>
          </div>
          <br></br>
          <div id="contact" className="bi-home-body-make-appointment-section">
            <div className="bi-home-container">
              <div className="bi-home-body-make-appointment-section-text">
                <h2>How do I make an appointment?</h2>
                To access counselling by Julie you will need to firstly visit a
                GP, psychiatrist, or pediatrician who will provide a referral
                and Mental Health Care Plan. <br></br>
                <br></br>
                You can then contact Julie directly for an appointment.<br></br>
                <br></br>
                No costs are involved to you as Julie bulk bills directly to
                Medicare on your behalf.<br></br>
                <div className="bi-empty-space">s</div>
                <br></br>
              </div>
            </div>
          </div>
          <div className="bi-home-body-contact-section">
            <table>
              <tbody>
                <tr>
                  <td>
                    <FaMapMarkerAlt className="bi-home-body-contact-section-icon" />
                  </td>
                  <td>
                    <a
                      href="https://goo.gl/maps/AFxxyPbULesAQuP86"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      3 Cambridge Road,
                      <br />
                      Russell Island
                      <br />
                      Queensland 4184
                    </a>
                  </td>
                  <td>
                    <FaEnvelope className="bi-home-body-contact-section-icon" />
                  </td>
                  <td>
                    <a href="mailto: julie@bayislandspsychology.com.au">
                      julie
                      <br />
                      @bayislands
                      <br />
                      psychology.com.au
                      <br />
                    </a>
                  </td>
                  <td>
                    <FaPhone className="bi-home-body-contact-section-icon" />
                  </td>
                  <td>
                    Ph: <a href="tel:0413154051">0413154051</a>
                    <br />
                    Fax: 38013897
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="bi-home-body-make-location-section">
          <div className="bi-home-container">
            <div className="bi-home-body-make-appointment-section-text">
              Bay Islands Psychology is located at 3 Cambridge Road, Russell
              Island and is easily accessed by the other South Moreton Bay
              Islands. If you are coming from Macleay, Lamb or Karragarra
              Islands it is about a 5 minute walk from the jetty. Walk up the
              hill, turn right into Bayview Road then left into Cambridge and it
              is on the right side of the street.
            </div>
          </div>
        </div>
        {/* <Maps /> */}
        <Footer />
      </div>
    );
  }
}

export default Home;
